import Vue from "vue";
import vueCustomElement from "vue-custom-element";
import "document-register-element/build/document-register-element";
import Appsignal from "@appsignal/javascript";
import { errorHandler } from "@appsignal/vue";
import App from "./App.vue";

Vue.config.productionTip = false;
Vue.use(vueCustomElement);
const appsignal = new Appsignal({
  key: process.env.VUE_APP_APPSIGNAL_API_KEY,
  environment:
    process.env.VUE_APP_APPSIGNAL_APP_ENV || process.env.VUE_APP_DEPLOY_ENV,
});

Vue.config.errorHandler = errorHandler(appsignal, Vue);

Vue.customElement("memoriamtv-embed", App);
