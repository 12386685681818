<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Player</title>
    <g
      id="Player"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle id="Oval" fill="#511966" cx="12" cy="12" r="11"></circle>
      <polygon
        id="Triangle"
        fill="#FFFFFF"
        transform="translate(13.000000, 12.000000) rotate(90.000000) translate(-13.000000, -12.000000) "
        points="13 6 20 18 6 18"
      ></polygon>
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-player",
};
</script>

<style lang="scss" scoped>
svg {
  // height: 24px;
  margin-top: 3px;
  vertical-align: top;
}
</style>
