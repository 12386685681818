<template>
  <section v-if="invalid" class="memoriam-tv--invalid">
    <p>
      Embed is verkeerd geconfigureerd. Kijk op
      <a href="https://info.memoriam.tv/embedding">
        https://info.memoriam.tv/embed
      </a>
      voor documentatie.
    </p>
  </section>
  <section v-else class="memoriam-tv--py-4">
    <div v-if="landingPageUrl" class="memoriam-tv--text-center">
      <h5
        class="memoriam-tv--h5 memoriam-tv--text-center"
        v-html="txtUnlocked"
      ></h5>
      <p>
        <a
          class="memoriam-tv--btn memoriam-tv--btn-primary memoriam-tv--mb-4"
          :href="landingPageUrl"
          v-html="txtLink"
        ></a>
      </p>
    </div>
    <div v-else>
      <h5
        class="memoriam-tv--h5 memoriam-tv--text-center"
        v-html="txtTitle"
      ></h5>
      <form class="memoriam-tv--text-center" @submit.prevent="onSubmit">
        <input
          type="text"
          class="memoriam-tv--mb-4 memoriam-tv--form-control memoriam-tv--input"
          id="unclockCode"
          maxlength="6"
          style="
            text-align: center;
            letter-spacing: 3px;
            width: auto;
            display: inline-block;
          "
          v-model="unlockCode"
        />
        <br />
        <button
          class="memoriam-tv--btn memoriam-tv--btn-primary memoriam-tv--mb-4"
          :disabled="disabled"
          v-html="txtSubmit"
        ></button>
        <p
          class="memoriam-tv--text-danger"
          v-if="invalidCode"
          v-html="txtError"
        ></p>
        <p
          class="memoriam-tv--text-danger memoriam-tv--loading"
          v-if="errored"
          v-html="txtConnectionError"
        ></p>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { isMobile } from "mobile-device-detect";
const api_endpoint = process.env.VUE_APP_API_ENDPOINT;

// ENCODE_CHARS = %w(0 1 2 3 4 5 6 7 8 9 A C D E F G H J K M N P Q R S T V W X Y Z)
// 0-9AC-HJKMNP-TV-Z)
// 0 -               9 A   C -       H   J K   M N   P -     T   V -     Z)
// 0 1 2 3 4 5 6 7 8 9 A   C D E F G H   J K   M N   P Q R S T   V W X Y Z)
// 0 1 2 3 4 5 6 7 8 9 A b C D E F G H i J K l M N o P Q R S T u V W X Y Z)
///                      B             I     L     O           U

export default {
  data() {
    return {
      loading: true,
      errored: false,
      invalid: false,
      unlockCode: "",
      disabled: true,
      landingPageUrl: false,
      invalidCode: false,
    };
  },
  props: {
    client_reference: { type: String, required: true },
    alternativeDomainName: { type: String, required: false },
    txtConnectionError: { type: String, required: true },
    txtTitle: { type: String, required: true },
    txtUnlocked: { type: String, required: true },
    txtLink: { type: String, required: true },
    txtSubmit: { type: String, required: true },
    txtError: { type: String, required: true },
  },
  watch: {
    unlockCode: function (newValue) {
      this.unlockCode = newValue
        .toUpperCase()
        .replace(/[^0-9AC-HJKMNP-TV-Z_]/g, "");
      this.disabled = this.unlockCode.length < 6;
      if (this.invalidCode) {
        this.invalidCode = this.unlockCode.length < 2;
      }
    },
  },
  methods: {
    setLoading(val) {
      this.loading = val;
      this.disabled = val || this.unlockCode.length < 6;
    },
    onSubmit() {
      const { unlockCode } = this;
      const body = {
        data: {
          id: this.client_reference,
          type: "embeds",
          attributes: {
            unlock_code: unlockCode,
          },
        },
      };
      this.errored = false;
      this.setLoading(true);

      axios
        .put(api_endpoint + "/" + this.client_reference, body)
        .then((response) => {
          if (response.data.included) {
            let url = response.data.included[0].attributes.landing_page_url;
            if (this.alternativeDomainName) {
              url = url.replace(
                "start.memoriam.tv",
                this.alternativeDomainName
              );
            }
            if (isMobile) {
              this.landingPageUrl = url;
            } else {
              window.open(url, "_memoriamTvVideoPlayer");
            }
          } else {
            this.unlockCode = "";
            this.invalidCode = true;
          }
        })
        .catch((error) => {
          console.error(error);
          this.errored = true;
        })
        .finally(() => this.setLoading(false));
    },
  },
  mounted() {
    if (!this.client_reference) {
      this.invalid = true;
    }
  },
};
</script>

<style lang="scss">
.memoriam-tv--h5 {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: "Roboto", sans-serif;
}
.memoriam-tv--form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.memoriam-tv--form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.memoriam-tv--input {
  text-align: center;
  letter-spacing: 3px;
  width: auto;
  display: inline-block;
}

.memoriam-tv--btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.memoriam-tv--btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.memoriam-tv--btn-primary {
  cursor: pointer;
}
.memoriam-tv--btn-primary.disabled,
.memoriam-tv--btn-primary:disabled {
  cursor: default;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.memoriam-tv--btn.disabled,
.memoriam-tv--btn:disabled {
  opacity: 0.65;
}
.memoriam-tv--text-center {
  text-align: center !important;
}
.memoriam-tv--mt-4 {
  margin-top: 1.5rem !important;
}
.memoriam-tv--mb-4 {
  margin-bottom: 1.5rem !important;
}
.memoriam-tv--text-danger {
  color: #dc3545 !important;
  animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.memoriam-tv--py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}
</style>
