<template>
  <div id="memoriamtv-embed">
    <section v-if="list_mode()">
      <List
        :client_reference="client_reference"
        :alternativeDomainName="alternative_domain_name"
        :txtConnectionError="txtConnectionError"
      />
    </section>
    <section v-else-if="code_mode()">
      <Code
        :client_reference="client_reference"
        :alternativeDomainName="alternative_domain_name"
        :txtConnectionError="txtConnectionError"
        :txtTitle="txtTitle"
        :txtSubmit="txtSubmit"
        :txtError="txtError"
        :txtUnlocked="txtUnlocked"
        :txtLink="txtLink"
      />
    </section>
  </div>
</template>

<script>
import List from "./components/List.vue";
import Code from "./components/Code.vue";

export default {
  name: "App",
  props: {
    client_reference: { type: String, required: true },
    alternative_domain_name: { type: String, required: false },
    txtTitle: {
      type: String,
      required: false,
      default: "Voer hier uw verkregen code in:",
    },
    txtSubmit: { type: String, required: false, default: "Verzend" },
    txtUnlocked: {
      type: String,
      required: false,
      default: "Code is correct.",
    },
    txtLink: {
      type: String,
      required: false,
      default: "Bekijk uitzending",
    },
    txtError: {
      type: String,
      required: false,
      default:
        "De ingevoerde code is niet correct. <br/>Probeer het nog een keer.",
    },
    txtConnectionError: {
      type: String,
      required: false,
      default:
        "Contact met de server is mislukt. <br/>Probeer het later opnieuw.",
    },
    mode: { type: String, required: false },
  },
  components: {
    List,
    Code,
  },
  methods: {
    code_mode() {
      return this.mode === "code";
    },
    list_mode() {
      return this.mode === "list" || this.mode === undefined;
    },
  },
};
</script>

<style lang="scss">
#memoriamtv-embed {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
