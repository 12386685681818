<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Private</title>
    <g
      id="Private"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M19.0714286,10.1875 L18.1071429,10.1875 L18.1071429,7.234375 C18.1071429,3.79726563 15.3669643,1 12,1 C8.63303571,1 5.89285714,3.79726563 5.89285714,7.234375 L5.89285714,10.1875 L4.92857143,10.1875 C3.86383929,10.1875 3,11.0693359 3,12.15625 L3,20.03125 C3,21.1181641 3.86383929,22 4.92857143,22 L19.0714286,22 C20.1361607,22 21,21.1181641 21,20.03125 L21,12.15625 C21,11.0693359 20.1361607,10.1875 19.0714286,10.1875 Z M15,10 L9,10 L9,7 C9,5.34583333 10.3458333,4 12,4 C13.6541667,4 15,5.34583333 15,7 L15,10 Z"
        id="Mask"
        fill="#511966"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-private",
};
</script>

<style lang="scss" scoped>
svg {
  // height: 24px;
  margin-top: 3px;
  vertical-align: top;
}
</style>
